import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Grid from '@mui/material/Grid';
import './About.css';
import {useMediaQuery} from "@mui/material";
import { useTranslation } from "react-i18next";


const itemData = [
    {
        img: 'https://i.imgur.com/1h4E05B.jpeg',
    },
    {
        img: 'https://i.imgur.com/MGRqb7l.jpeg',
    },
    {
        img: 'https://i.imgur.com/EpkUqzp.jpeg',
    },
    {
        img: 'https://i.imgur.com/nb8nTWp.jpeg',
    },
    {
        img: 'https://i.imgur.com/XVmS089.jpeg',
    },
    {
        img: 'https://i.imgur.com/bH3HBAz.jpeg',
    },
];

const About = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { t } = useTranslation();

    return (
        <>
            <Grid container spacing={2} columns={16}>
                <Grid item xs={8}>
                    <div className='about'>
                        <h1>
                            {t("about.welcome")} <span className='about__name'>{t("about.name")}.</span>
                        </h1>
                        <h2 className='about__role'>{t("about.role")}</h2>
                        <p className='about__desc'>{t("about.description")}</p>
                        <p className='about__desc'>{t("about.text")}</p>
                        <div className='about__contact center'>
                            <a href="tel:+41767860222">
                                <span type='button' className='btn btn--outline'>
                                    {t("about.button")}
                                </span>
                            </a>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={8}>
                    <Box sx={{ overflowY: 'hidden'}}>
                        <ImageList variant="woven" cols={matches ? (3) : (1)} gap={matches ? (18) : (0)}>
                            {itemData.slice(0, matches ? itemData.length : 5).map((item) => (
                                <ImageListItem key={item.img}>
                                    <img
                                        alt={item.img}
                                        srcSet={`${item.img}`}
                                        src={`${item.img}`}
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>
                </Grid>
            </Grid>
    </>

    )
}

export default About
