import React from "react";
import styled from 'styled-components';
import {useTranslation} from "react-i18next";

function TeamCard({
                      image,
                      name,
                      work,
                      ...rest
                  }) {
    const { t } = useTranslation();
    return (
        <TestimonialCard {...rest}>
            <TestimonialImage>
                <TestimonialImageImg src={image} alt="testimonial" />
                <TestimonialDetails className="testimonial-details">
                    <TestimonialImageP>{name}</TestimonialImageP>
                    <TestimonialImageP>{t("team.job")}</TestimonialImageP>
                </TestimonialDetails>
            </TestimonialImage>
        </TestimonialCard>
    );
}


const TestimonialCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 18vw;
  height: 15vw;
  min-height: 350px;
  margin: 15px auto auto;
  @media (max-width: 500px) {
    width: 250px;
  }
`;

const TestimonialImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  text-align: center;
`;

const TestimonialImageImg = styled.img`
  width: 10vw;
  height: 10vw;
  margin: 5px;
    min-width: 200px;
    min-height: 200px;
`;

const TestimonialImageP = styled.p`
    font-size: max(0.5vw, min(17px));

  &:first-child {
      font-size: max(0.8vw, min(18px));
    font-weight: 660;
  }
`;

const TestimonialDetails = styled.div`
  margin-bottom: 40px;
`;

export default TeamCard;