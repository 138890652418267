import React from 'react'
import './endSection.css'
import {useTranslation} from "react-i18next";

const EndSection = () => {
    const { t } = useTranslation();

    return (
        <section className='inDev' id='event'>
            <div className='inDev center'>
                <h2 className='inDev__role'>{t("Foot.name")}</h2>
                <p className='inDev__desc'>{t("Foot.text1")}</p>
            </div>
        </section>
    )
}

export default EndSection
