import './Faq.css'
import {useTranslation} from "react-i18next";

const Faq = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className='section faq center' id='contact'>
                <h2 className='section__title'>Cerruti Immobilier SA</h2>
                <p className='faq__desc'>{t("faq.description")}</p>
                <p className='faq__desc'>{t("faq.text1")}</p>
                <p className='faq__desc'>{t("faq.text2")}</p>
                <br/>
                <div style={{width: "100%"}}>
                    <iframe width="100%" height="600" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Route%20de%20l'A%C3%A9roport%2015,%201215%20Gen%C3%A8ve%2015%20A%C3%A9roport,%20Meyrin+(Cerruti%20Immobilier)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                    </iframe>
                </div>
            </section>
        </>
    )
}

export default Faq