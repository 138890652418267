import './Team.css'
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import TeamCard from "../../../components/TeamCard/TeamCard"
import {useTranslation} from "react-i18next";

const items = [
    <TeamCard
        image="/img/Team/Gedik.png"
        name="Vedat GEDIK"
        work="Co-founder"
    />,
    <TeamCard
        image="/img/Team/Seigneur.png"
        name="Matthieu SEIGNEUR"
        work="Co-founder"
    />,
    <TeamCard
        image="/img/Team/Terence.png"
        name="Terence NIBA"
        work="Co-founder"
    />,
    <TeamCard
        image="/img/Team/Pogba.png"
        name="Mathias POGBA"
        work="Co-founder"
    />,
    <TeamCard
        image="/img/Team/Gerbreziabiher.png"
        name="Michael GEBREZIABIHER"
        work="Co-founder"
    />,
    <TeamCard
        image="/img/Team/Toure.png"
        name="Souleymane TOURE"
        work="Co-founder"
    />,
    <TeamCard
        image="/img/Team/Cerruti.png"
        name="John CERRUTI"
        work="Co-founder"
    />
];

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1000: { items: 2 }
};
const Team = () => {
    const { t } = useTranslation();

    return (
        <div id='team'>
            <section className='section team center' >
                <h2 className='section__title'>{t("team.title")}</h2>
            </section>
            <div className="testimonial-container">
                <AliceCarousel
                    mouseTracking
                    items={items}
                    responsive={responsive}
                    autoPlay
                    infinite
                    autoPlayInterval={2000}
                    animationDuration={2000}
                />
            </div>
        </div>

    )
}

export default Team