import './ProjectContainer.css'

const ProjectContainer = ({ project }) => (

    <div className='project'>
        <h3>{project.name}</h3>
        <p className='project__description'>{project.description}</p>
    </div>
)

export default ProjectContainer
