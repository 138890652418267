import "./Success.css";
import CountUp from "react-countup";
import { motion } from "framer-motion";
import Grid from "@mui/material/Grid";
import {Stack, useMediaQuery} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

const Success = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { t } = useTranslation();

    return (
        <section  className='section success center' id='sucess'>
                <div className='title'>
                    <p className='success__title'>
                        {t("data.title")}
                    </p>
                </div>
                <Grid container padding={6} spacing={2} columns={matches ? (2) : (1)} className='mobile_grid'>
                    <Grid item xs={1}>
                        <h1>
                            {t("data.big1")}<br/>
                            {t("data.big2")}<br/>
                        </h1>
                        <p className='success__desc'>{t("data.description")}</p>
                        <br/>
                        <Stack direction="row" spacing={1}>
                            <h4 className='success__name'>{t("data.stack1Text1")} {t("data.to")}</h4>
                            <h2><span className='success__nb'> <CountUp start={0} end={30} duration={4}/>%</span></h2>
                            <h4 className='success__name'>{t("data.stack1Text2")}</h4>
                        </Stack>
                        <br/>
                        <Stack direction="row" spacing={1}>
                            <h2>
                                <span className='success__nb'><CountUp start={0} end={50} duration={4}/>%</span>
                            </h2>
                            <h4 className='success__name'>{t("data.stack2Text1")}</h4>
                        </Stack>
                        <br/>
                        <Stack direction="row" spacing={1}>
                            <h4 className='success__name'>{t("data.stack3Text1")}</h4>
                            <h2>
                                <span className='success__nb'>CHF <CountUp start={0} end={80000} duration={2}/></span>
                            </h2>
                        </Stack>
                    </Grid>
                    <Grid item xs={1}>
                    <motion.div
                                initial={{ x: "7rem", opacity: 0 }}
                                animate={{ x: "0", opacity: 1 }}
                                transition={{
                                    duration: 2,
                                    type: "spring"
                                }}
                                className="image-container"
                            >
                                <img src={"https://i.imgur.com/stk368f.jpeg"} alt="success" />
                        </motion.div>
                    </Grid>
                </Grid>
        </section>
    );
};
export default Success
