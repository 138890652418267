import { useContext } from 'react'
import { ThemeContext } from './styles/theme'
import Header from './screens/sections/Header/Header'
import About from './screens/sections/About/About'
import Projects from './screens/sections/Projects/Projects'
import Event from "./screens/sections/Event/Event";
import './App.css'
import { Fade } from "react-awesome-reveal";
import Faq from "./screens/sections/FAQ/Faq";
import Success from "./screens/sections/Success/Success";
import Team from "./screens/sections/Team/Team";
import EndSection from "./screens/sections/Footer/endSection"
import Footer from "./screens/sections/Footer/footer";

function App() {
  const [{ themeName }] = useContext(ThemeContext)

  return (
      <div id='top' className={`${themeName} app`}>
        <Header/>
        <main>
          <Fade left>
            <About/>
          </Fade>
          <Fade left>
            <Projects/>
          </Fade>
          <Fade left>
            <Event/>
          </Fade>
          <Fade>
            <Success/>
          </Fade>
          <Fade>
            <Faq/>
          </Fade>
          <Fade>
            <Team/>
          </Fade>
          <Fade>
            <EndSection/>
          </Fade>
          <Fade>
            <Footer/>
          </Fade>
        </main>
      </div>
  );
}

export default App;
